import React, { useEffect, useState } from "react"
import Header from "../../../components/header"
import GoBack from "../../../components/goBack"
import moment from "moment"
import cogoToast from "cogo-toast"
import { useDispatch, useSelector } from "react-redux"
import { createRequest } from "../../../redux/SalaryAdvance/advanceAction"
//import ModalComponent from "../../../components/Modals/modal"

export const CreateRequestPage = () => {
    const [amount, setAmount] = useState(0);
    const [month, setMonth] = useState("");
    const [wordCount, setWordCount] = useState(0);
    const [reason, setReason] = useState("");

    const {loading} = useSelector((state)=>state.advance)

    const today = new Date();

    const mth = new Date(today.getFullYear(), today.getMonth(), 2);

    const monthName = moment(mth).format("MMMM");

    const monthDigit = moment(mth).format("MM");

    const dispatch = useDispatch();

    const handleWordCount = (event) => {
        const text = event.target.value;
        setReason(text);
        const words = text.trim().split(/\s+/); // Split on whitespace (including multiple spaces)
        setWordCount(words.length);
        if (words.length > 1000) {
            // Prevent further typing if word count exceeds 1000
            cogoToast.error("Word count exceeds 1000 characters");
            return;
        }
        setWordCount(words.length);
    };

    const handleSubmit = (event) => {

        event.preventDefault();
        if (!amount && !month) {
            cogoToast.error("Please fill all the fields")
        } else {
            dispatch(createRequest({ for_month: monthDigit, amount: amount, request_reason: reason }));
        }


    }

    useEffect(() => {
        setMonth(moment(monthName))
    }, [monthName]);


    return (
        <>
            <GoBack />
            <Header text={"Request Salary Advance"}>
            </Header>
            <div className="w-full h-full px-4 mt-4 bg-white rounded-[8px] py-8">
                <p className="py-4 text-[#7C8D7E] text-sm">Simply fill out the form below with the amount you need and the reason for your request. Our HR team will review it promptly and get back to you with a decision.</p>
                <div className="grid cols-1 md:grid-cols-6 space-x-4">
                    <div className="col-span-2">
                        <div>
                            <div>
                                <label htmlFor="amount" className="font-[400] text-[14px] leading-[18px] text-[#ABB6AC]">
                                    Advance Month
                                </label>
                                <input
                                    type="text"
                                    name="month"
                                    value={monthName}
                                    disabled={true}
                                    onChange={(event) => setMonth(event.target.value)}
                                    className="outline-0 h-[44px] bg-[#F7F7F7]  rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-normal text-[14px] leading-[18px] text-[#ABB6AC] mb-[24px]"
                                    placeholder="month"
                                />
                            </div>
                            <div >
                                <label htmlFor="amount" className="font-[400] text-[14px] leading-[18px] text-[#344335]">
                                    Salary Advance Amount
                                </label>
                                <input
                                    type="text"
                                    name="amount"
                                    value={amount}
                                    onChange={(event) => setAmount(event.target.value)}
                                    className="outline-0 h-[44px] bg-[#F7F7F7]  rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-[#344335] mb-[24px]"
                                    placeholder="Enter amount (₦)"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4 ">
                        <label htmlFor="reason" className="font-[400] text-[14px] leading-[18px] text-secondary">
                            Reason for Advance
                        </label>
                        <textarea
                            id="reason"
                            name="reason"
                            onChange={handleWordCount}
                            className="outline-0 h-[140px] bg-[#F7F7F7] rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary"
                        ></textarea>
                        <div className="text-xs px-5 text-[#5C715E]">{wordCount}/1000 words</div>
                    </div>
                </div>
                <div className="flex flex-row justify-end gap-6 mt-6">
                    <div>
                        <button className="outline-none bg-transparent border-primary text-primary font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px] hover:bg-primary hover:border hover:border-primary hover:text-white transition ease-in-out delay-100">
                            Cancel
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={handleSubmit}
                            disabled={loading}
                            className="outline-none bg-primary border border-primary text-white font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px] hover:bg-primaryhover hover:border hover:border-primary hover:text-white transition ease-in-out delay-100">
                            Proceed with request
                        </button>
                    </div>
                </div>
            </div>


        </>
    )
}