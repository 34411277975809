import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'
import cogoToast from 'cogo-toast'
import { redirect } from 'react-router-dom'


const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  }


//retrieve single advance
export const RetrieveSingleAdvance = createAsyncThunk(
    'RetrieveSingleAdvance',
    async({id}, {rejectWithValue, dispatch}) => {
     try {
        const res = await axios.get(`${apiUrl}payslip/${id}/get-salary-advancement/`,
        {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}` 
            }
        }
        )
    if(res.status === 200){
        return res.data
    }
    
  } catch (error) {
    if(error.response.status === 401){
      cogoToast.error("Authentication credentials were not provided.")
      dispatch({type: 'auth/Logout'})
      redirect('/login')
    }
    else{
      return rejectWithValue(error.response)
    }
  }
    }
)

//get payment table
export const PaymentTable = createAsyncThunk(
    'PaymentTable',
    async({page, page_size, paymentStatus, approvalStatus}, {rejectWithValue, dispatch}) => {
     try {
        const res = await axios.get(`${apiUrl}payslip/get-salary-advancements?page_size=${page_size}&page=${page}&payment_status=${paymentStatus}&approval_status=${approvalStatus}`,
        {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}` 
            }
        }
        )
    if(res.status === 200){
        return res.data
    }
    
  } catch (error) {
    if(error.response.status === 401){
      cogoToast.error("Authentication credentials were not provided.")
      dispatch({type: 'auth/Logout'})
      redirect('/login')
    }
    else{
      return rejectWithValue(error.response)
    }
  }
    }
)

//pay salary advance
export const paySalaryAdvance = createAsyncThunk(
  "paySalaryAdvance",
  async ({ employee_id, amount }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}payslip/pay-advance-salary/`,
        { employee_id, amount },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        cogoToast.success(res.data.message.message || "Salary advancement is being processed...!");
        return res.data;
      }

    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response.status === 400) {
        cogoToast.error(error.response.data.error);
      } else {
        cogoToast.error(`${error.response.data.error}`);
        return rejectWithValue(error.response);
      }
    }
  }
);

//create a request
export const createRequest = createAsyncThunk(
  "createRequest",
  async ({for_month, amount, request_reason}, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}payslip/request-advance-salary/`,
        { request_reason, amount, for_month },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 201) {
        cogoToast.success(res.data.message.message || "Salary advance request submitted successfully!");
        return res.data;
      }

    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response.status === 400) {
        cogoToast.error(error.response.data.error);
      } else {
        cogoToast.error(`${error.response.data.error}`);
        return rejectWithValue(error.response);
      }
    }
  }
)

//update salary advance
export const updateSalaryAdvance = createAsyncThunk(
  "updateSalaryAdvance",
  async ({ remark, approval_status}, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}payslip/request-advance-salary/`,
        { remark, approval_status },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 201) {
        cogoToast.success(res.data.message.message || `Advancement log has been ${approval_status.toUpperCase()} successfully!`);
        return res.data;
      }

    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response.status === 400) {
        cogoToast.error(error.response.data.error);
      } else {
        cogoToast.error(`${error.response.data.error}`);
        return rejectWithValue(error.response);
      }
    }
  }
)