import React, {} from "react"


export const SalaryAdvanceRequestTable = () => {

    return (
        <>
    
        
        </>
    )
}