import React, { useState, useMemo, useCallback, useEffect } from "react";
import { debounce } from 'lodash'
import LoadingStateComponent from "../../../components/Empty State/loadingstate";
//import EmptyStateComponent from "../../../components/Empty State/emptystate";
import SearchComponent from "../../../components/search";
//import PaginationComponent from "../../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { PaymentTable } from "../../../redux/SalaryAdvance/advanceAction";

const SalaryAdvancePaymentTable = () => {
  const [typeID, setTypeID] = useState(1);
  // handle search
  const [searchValue, setSearchValue] = useState('');
  //pagination
 // const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const dispatch = useDispatch();
  const { loading, payments } = useSelector((state) => state?.advance)

  const callTypes = [
    {
      id: 1,
      title: 'All',
      tag: 'all'
    },
    {
      id: 2,
      title: 'Pending',
      tag: 'pending'
    },
    {
      id: 3,
      title: 'Processing',
      tag: 'processing'
    },
    {
      id: 4,
      title: 'Completed',
      tag: 'completed'
    },
    {
      id: 5,
      title: 'Failed',
      tag: 'failed'
    }

  ]

  const getPaymentStatusBg = (val) => {
    let result;
    switch (val) {
      case 'FAILED':
        result = 'error-badge'
        break
      case 'PROCESSING':
        result = 'dispatched-badge'
        break
      case 'COMPLETED':
        result = 'success-badge'
        break
      case 'PENDING':
        result = 'pending-badge'
        break
      case 'DONE':
        result = 'done-badge'
        break
      case 'PAY':
        result = 'pay-badge'
        break
      default:
        result = 'dispatched-badge'
        break
    }

    return result
  }

  const getPaymentStatusText = (val) => {
    let result
    switch (val) {
      case 'PROCESSING':
        result = 'Processing'
        break
      case 'FAILED':
        result = 'Failed'
        break
      case 'PENDING':
        result = 'Pending'
        break
      case 'COMPLETED':
        result = 'Completed'
        break
      default:
        result = 'Processing'
        break
    }
    return result
  }


  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        //console.log(val); 
      }, 750),
    [],
  )

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value
      setSearchValue(val)
      handleSearch(val)
    },
    [handleSearch],
  )

  // const toggleModal = () => {
  //   //console.log('toggle')
  // }

  const toggleType = (id, tag) => {
    setTypeID(id)
    switch (id) {
      case 1:
        dispatch(PaymentTable({ type: tag, page: 1, page_size: pageSize }))
        break;
      case 2:
        dispatch(PaymentTable({ type: tag, page: 1, page_size: pageSize }))
        break;
      case 3:
        dispatch(PaymentTable({ type: tag, page: 1, page_size: pageSize }))
        break;
      case 4:
        dispatch(PaymentTable({ type: tag, page: 1, page_size: pageSize }))
        break;
      case 5:
        dispatch(PaymentTable({ type: tag, page: 1, page_size: pageSize }))
        break;
      default:
        dispatch(PaymentTable({ type: 'all', page: 1, page_size: pageSize }))
        break;
    }
  }

  //empty state text content
  // const textContent = (id) => {
  //   let res;
  //   switch (id) {
  //     case 1:
  //       res = 'Oops! No payslip available'
  //       break;
  //     case 2:
  //       res = 'Oops! No pending payslip(s) available.'
  //       break;
  //     case 3:
  //       res = 'Oops! No processing payslip(s) available.'
  //       break;
  //     case 4:
  //       res = 'Oops! No completed payslip(s) available.'
  //       break;
  //     case 5:
  //       res = 'Oops! No failed payslip(s) available.'
  //       break;
  //     default:
  //   }
  //   return res;
  // }

  useEffect(() => {
    dispatch(PaymentTable({ type: 'all', page: 1, page_size: pageSize }))
  }, [dispatch, pageSize])


  console.log(payments, "payments")

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          {/* search */}
          <div className="flex gap-4 mt-4">
            <div className="w-full">
              <SearchComponent
                placeholder="Search..."
                searchValue={searchValue}
                handleChange={handleChange}
              />
            </div>
          </div>

          {/* tabs */}
          <ul className="flex mt-5 gap-5 items-center">
            {callTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id, item.tag)}
                className={
                  typeID === item.id
                    ? 'flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer'
                    : 'flex gap-2 items-center pb-1 cursor-pointer'
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? 'text-primary text-sm font-semibold'
                      : 'text-[#96A397] text-sm font-semibold'
                  }
                >
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
        {/* Table  */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Date
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Employee ID
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Employee Name
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Amount Requested
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Reason for Request
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Approval Status
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Payment Status
                </th>
                {(typeID === 2 || typeID === 5) && <th scope="col" className="px-4 py-4 font-semibold">
                  Action
                </th>}
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {payments.length > 0 && payments.map((item) => (
                  <tr
                    key={item.id}
                    className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                  >

                    <td className="py-4 px-4 text-[#5C715E]">
                      {"N/A"}
                    </td>
                    <td className="py-4 px-4 text-[#5C715E]">
                      {"N/A"}
                    </td>
                    <td className="py-4 px-4 text-[#5C715E]">
                      {"N/A"}
                    </td>
                    <td className="py-4 px-4 text-[#5C715E]">
                      {"N/A"}
                    </td>
                    <td className="py-4 px-4 text-[#5C715E]">
                      {item.net_salary ? item.net_salary.amount?.toLocaleString() : "N/A"}
                    </td>
                    <td className="py-4 px-4 text-[#5C715E]">
                      <div className="flex">
                        <div className={getPaymentStatusBg(item.payment_status)}>
                          <p>{getPaymentStatusText(item.payment_status)}</p>
                        </div>
                      </div>
                    </td>
                    {/* {typeID !== 1 && typeID !== 3 && typeID !== 4 && <td className="py-4 px-4">
                      <div className="flex">
                        <div
                          className={`${checkedpayments.length > 1 ? "pointer-events-none" : ""
                            } ${getPaymentStatusBg(
                              item.payment_status === "COMPLETED" ? "DONE" : "PAY"
                            )}`}
                          onClick={() => handleModalClick(item.employee.id, item.id, item.date_of_entry)}
                        >
                          <p>{getApprovedPayslipActionText(item.payment_status === "COMPLETED" ? "DONE" : "PAY")}</p>
                        </div>
                      </div>
                    </td>} */}
                  </tr>
                ))
                }
              </tbody>
            )}
          </table>


          {loading &&
            <LoadingStateComponent />
          }
        </div>
      
      </div>

    </>
  );
};

export default SalaryAdvancePaymentTable;
